import axios from "axios";
import Header from "components/header";
import Reveal from "motion/Reveal";

import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  FaEnvelope,
  FaInstagram,
  FaPhone,
  FaTelegramPlane,
} from "react-icons/fa";
import Particles from "react-particles";
import { toast } from "react-toastify";
import { loadSlim } from "tsparticles-slim";

export const BASE_URL = "https://knock-web-api.onrender.com/api";
export const CDN_BASE_URL = "https://knock-web-api.onrender.com";

const Request = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);
  const [t, i18n] = useTranslation("global");
  const particlesLoaded = useCallback(async (container) => {}, []);

  const [name, setname] = useState();
  const [phone, setphone] = useState();
  const [content, setcontent] = useState();
  const [loading, setloading] = useState();

  async function handleSend(e) {
    e.preventDefault();
    if (!name || !phone || !content) {
      return toast.error(t("all-fild-required"));
    }
    setloading(true);

    // const formdata = new FormData();
    // formdata.append("name", name);
    // formdata.append("phone", phone);
    // formdata.append("content", content);
    await axios({
      url: BASE_URL + "/send",
      method: "POST",
      data: { name, content, phone },
    })
      .then((e) => {
        toast.success(t("success"));
        setname("");
        setphone("");
        setcontent("");
        setloading(false);
      })
      .catch((err) => {
        toast.error(err);
        setname("");
        setphone("");
        setcontent("");
        setloading(false);
      });
  }
  return (
    <>
      <Helmet>
        <title>{t("request-seo-title")}</title>
        <meta name="description" content={t("request-desc")} />
        <meta name="keywords" content={t("home-keywords")} />
        <meta name="og:description" content={t("request-desc")} />
        <meta name="og:title" content={t("request-seo-title")} />
      </Helmet>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          autoPlay: true,
          background: {
            color: {
              value: "#000",
            },
            position: "50% 50%",
            repeat: "no-repeat",
            size: "20%",
            opacity: 1,
          },
          backgroundMask: {
            composite: "destination-out",
            cover: {
              color: {
                value: "#fff",
              },
              opacity: 1,
            },
            enable: false,
          },
          clear: true,
          defaultThemes: {},
          delay: 0,
          fullScreen: {
            enable: true,
            zIndex: 0,
          },
          detectRetina: true,
          duration: 0,
          fpsLimit: 120,
          interactivity: {
            detectsOn: "window",
            events: {
              onClick: {
                enable: true,
                mode: "repulse",
              },
              onDiv: {
                selectors: [],
                enable: false,
                mode: [],
                type: "circle",
              },
              onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                  enable: false,
                  force: 2,
                  smooth: 10,
                },
              },
              resize: {
                delay: 0.5,
                enable: true,
              },
            },
            modes: {
              trail: {
                delay: 1,
                pauseOnStop: false,
                quantity: 1,
              },
              attract: {
                distance: 200,
                duration: 0.4,
                easing: "ease-out-quad",
                factor: 1,
                maxSpeed: 50,
                speed: 1,
              },
              bounce: {
                distance: 200,
              },
              bubble: {
                distance: 250,
                duration: 2,
                mix: false,
                opacity: 0,
                size: 0,
                divs: {
                  distance: 200,
                  duration: 0.4,
                  mix: false,
                  selectors: [],
                },
              },
              connect: {
                distance: 80,
                links: {
                  opacity: 0.5,
                },
                radius: 60,
              },
              grab: {
                distance: 400,
                links: {
                  blink: false,
                  consent: false,
                  opacity: 1,
                },
              },
              push: {
                default: true,
                groups: [],
                quantity: 4,
              },
              remove: {
                quantity: 2,
              },
              repulse: {
                distance: 400,
                duration: 0.4,
                factor: 100,
                speed: 1,
                maxSpeed: 50,
                easing: "ease-out-quad",
                divs: {
                  distance: 200,
                  duration: 0.4,
                  factor: 100,
                  speed: 1,
                  maxSpeed: 50,
                  easing: "ease-out-quad",
                  selectors: [],
                },
              },
              slow: {
                factor: 3,
                radius: 200,
              },
              light: {
                area: {
                  gradient: {
                    start: {
                      value: "#ffffff",
                    },
                    stop: {
                      value: "#000000",
                    },
                  },
                  radius: 1000,
                },
                shadow: {
                  color: {
                    value: "#000000",
                  },
                  length: 2000,
                },
              },
            },
          },
          manualParticles: [],
          particles: {
            bounce: {
              horizontal: {
                value: 1,
              },
              vertical: {
                value: 1,
              },
            },
            collisions: {
              absorb: {
                speed: 2,
              },
              bounce: {
                horizontal: {
                  value: 1,
                },
                vertical: {
                  value: 1,
                },
              },
              enable: false,
              maxSpeed: 50,
              mode: "bounce",
              overlap: {
                enable: true,
                retries: 0,
              },
            },
            color: {
              value: "#ffffff",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  speed: 1,
                  decay: 0,
                  delay: 0,
                  sync: true,
                  offset: 0,
                },
                s: {
                  count: 0,
                  enable: false,
                  speed: 1,
                  decay: 0,
                  delay: 0,
                  sync: true,
                  offset: 0,
                },
                l: {
                  count: 0,
                  enable: false,
                  speed: 1,
                  decay: 0,
                  delay: 0,
                  sync: true,
                  offset: 0,
                },
              },
            },
            effect: {
              close: true,
              fill: true,
              options: {},
              type: [],
            },
            groups: {},
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                distance: 200,
                enable: false,
                rotate: {
                  x: 3000,
                  y: 3000,
                },
              },
              center: {
                x: 50,
                y: 50,
                mode: "percent",
                radius: 0,
              },
              decay: 0,
              distance: {},
              direction: "none",
              drift: 0,
              enable: true,
              gravity: {
                acceleration: 9.81,
                enable: false,
                inverse: false,
                maxSpeed: 50,
              },
              path: {
                clamp: true,
                delay: {
                  value: 0,
                },
                enable: false,
                options: {},
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              },
              random: false,
              size: false,
              speed: {
                min: 0.1,
                max: 1,
              },
              spin: {
                acceleration: 0,
                enable: false,
              },
              straight: false,
              trail: {
                enable: false,
                length: 10,
                fill: {},
              },
              vibrate: false,
              warp: false,
            },
            number: {
              density: {
                enable: true,
                width: 1920,
                height: 1080,
              },
              limit: {
                mode: "delete",
                value: 0,
              },
              value: 120,
            },
            opacity: {
              value: {
                min: 0.1,
                max: 1,
              },
              animation: {
                count: 0,
                enable: true,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
              },
            },
            reduceDuplicates: false,
            shadow: {
              blur: 0,
              color: {
                value: "#000",
              },
              enable: false,
              offset: {
                x: 0,
                y: 0,
              },
            },
            shape: {
              close: true,
              fill: true,
              options: {},
              type: "circle",
            },
            size: {
              value: {
                min: 1,
                max: 3,
              },
              animation: {
                count: 0,
                enable: false,
                speed: 5,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
              },
            },
            stroke: {
              width: 0,
            },
            zIndex: {
              value: 0,
              opacityRate: 1,
              sizeRate: 1,
              velocityRate: 1,
            },
            destroy: {
              bounds: {},
              mode: "none",
              split: {
                count: 1,
                factor: {
                  value: 3,
                },
                rate: {
                  value: {
                    min: 4,
                    max: 9,
                  },
                },
                sizeOffset: true,
                particles: {},
              },
            },
            roll: {
              darken: {
                enable: false,
                value: 0,
              },
              enable: false,
              enlighten: {
                enable: false,
                value: 0,
              },
              mode: "vertical",
              speed: 25,
            },
            tilt: {
              value: 0,
              animation: {
                enable: false,
                speed: 0,
                decay: 0,
                sync: false,
              },
              direction: "clockwise",
              enable: false,
            },
            twinkle: {
              lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
              particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
            },
            wobble: {
              distance: 5,
              enable: false,
              speed: {
                angle: 50,
                move: 10,
              },
            },
            life: {
              count: 0,
              delay: {
                value: 0,
                sync: false,
              },
              duration: {
                value: 0,
                sync: false,
              },
            },
            rotate: {
              value: 0,
              animation: {
                enable: false,
                speed: 0,
                decay: 0,
                sync: false,
              },
              direction: "clockwise",
              path: false,
            },
            orbit: {
              animation: {
                count: 0,
                enable: false,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
              },
              enable: false,
              opacity: 1,
              rotation: {
                value: 45,
              },
              width: 1,
            },
            links: {
              blink: false,
              color: {
                value: "#fff",
              },
              consent: false,
              distance: 100,
              enable: false,
              frequency: 1,
              opacity: 1,
              shadow: {
                blur: 5,
                color: {
                  value: "#000",
                },
                enable: false,
              },
              triangles: {
                enable: false,
                frequency: 1,
              },
              width: 1,
              warp: false,
            },
            repulse: {
              value: 0,
              enabled: false,
              distance: 1,
              duration: 1,
              factor: 1,
              speed: 1,
            },
          },
          pauseOnBlur: true,
          pauseOnOutsideViewport: true,
          responsive: [],
          smooth: false,
          style: {},
          themes: [],
          zLayers: 100,
          name: "NASA",
          motion: {
            disable: false,
            reduce: {
              factor: 4,
              value: true,
            },
          },
        }}
      />
      <Header />
      <main
        style={{ zIndex: "100", position: "relative" }}
        id="main"
        data-aos="fade"
        data-aos-delay="1500"
        className="pt-md-5"
      >
        <div class="page-header d-flex align-items-center">
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 text-center">
                <Reveal>
                  <h1>{t("request-title")}</h1>
                </Reveal>
                <Reveal>
                  <p>{t("request-desc")}</p>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        <div class="contact">
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div class="col-lg-3">
                <Reveal>
                  <a
                    title={t("email")}
                    href="https://t.me/alinaderi789"
                    class="info-item d-flex"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTelegramPlane />
                    <div>
                      <h4>{t("telegram")}:</h4>
                      <p>alinaderi789</p>
                    </div>
                  </a>
                </Reveal>
              </div>

              <div class="col-lg-3">
                {" "}
                <Reveal>
                  <a
                    title={t("email")}
                    href="mailto:malinaderi1@outlook.com"
                    class="info-item d-flex"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaEnvelope />
                    <div>
                      <h4>{t("email")}:</h4>
                      <p>malinaderi1@outlook.com</p>
                    </div>
                  </a>{" "}
                </Reveal>
              </div>

              <div class="col-lg-3">
                {" "}
                <Reveal>
                  <a
                    title={t("instagram")}
                    href="https://www.instagram.com/knockweb_/"
                    class="info-item d-flex"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram />
                    <div>
                      <h4>{t("instagram")}</h4>
                      <p dir="ltr">1alinaderi</p>
                    </div>
                  </a>{" "}
                </Reveal>
              </div>
            </div>

            <div class="row justify-content-center mt-4">
              <div class="col-lg-9">
                <form class="php-email-form pb-5 px-1">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <Reveal>
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          id="name"
                          value={name}
                          placeholder={t("form-name")}
                          onChange={(e) => setname(e.target.value)}
                          required
                        />{" "}
                      </Reveal>
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      {" "}
                      <Reveal>
                        <input
                          type="text"
                          class="form-control"
                          name="phone"
                          value={phone}
                          id="phone"
                          onChange={(e) => setphone(e.target.value)}
                          placeholder={t("form-phone")}
                          required
                        />{" "}
                      </Reveal>
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    {" "}
                    <Reveal>
                      <textarea
                        class="form-control"
                        onChange={(e) => setcontent(e.target.value)}
                        value={content}
                        name="content"
                        rows="5"
                        placeholder={t("form-content")}
                        required
                      />{" "}
                    </Reveal>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    {loading ? (
                      <div
                        id="spinner"
                        className="show d-flex align-items-center justify-content-center"
                      >
                        <div
                          className="spinner-border text-brand"
                          style={{ width: "2rem", height: "2rem" }}
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <Reveal>
                        <button onClick={handleSend} type="submit">
                          {t("send")}
                        </button>{" "}
                      </Reveal>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Request;
