import Home from "pages/Home";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Request from "pages/Request";
import ExamplePage from "pages/ExamplePage";
import { useTranslation } from "react-i18next";

function App() {
  const [t] = useTranslation("global");
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Home />
        </>
      ),
    },
    {
      path: "/request",
      element: (
        <>
          <Request />
        </>
      ),
    },
    {
      path: "/portfolio/:name",
      element: (
        <>
          <ExamplePage />
        </>
      ),
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <footer className="footer">
        <p>{t("copyright")}</p>
      </footer>
    </>
  );
}

export default App;
