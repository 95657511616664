import React from "react";
import { useTranslation } from "react-i18next";
import SaturnAnimation from "../animations/Saturn.json";
import AstronautAnimation from "../animations/Astronaut.json";
import Lottie from "lottie-react";
import Reveal from "../motion/Reveal";

const Welcome = () => {
  const [t, i18n] = useTranslation("global");

  function handleScrool(params) {
    window.scroll({ top: window.innerHeight });
  }
  return (
    <section
      id="hero"
      className="hero d-flex flex-column justify-content-center align-items-center"
      data-aos="fade"
      data-aos-delay="1500"
      style={{ height: "100vh" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center px-4 px-lg-0">
            <Reveal>
              {" "}
              <h1 dangerouslySetInnerHTML={{ __html: t("welcome") }}></h1>
            </Reveal>
            <Reveal>
              {" "}
              <p>{t("welcome-text")}</p>
            </Reveal>
            <Reveal>
              <button onClick={handleScrool} className="btn-get-started z-10">
                {t("welcome-button")}
              </button>
            </Reveal>
          </div>
          <span className="welcome_saturn_1">
            <Reveal head>
              <Lottie animationData={SaturnAnimation} loop={true}/>
            </Reveal>
          </span>
          <span className="welcome_saturn_2">
            <Reveal head>
              <Lottie animationData={AstronautAnimation} loop={true} />
            </Reveal>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
